body {
  background-color: black;
  color: white;
  font-family: Roboto Mono;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes flashAnimation {
  0% { color: white; }
  75% { color: orange; }
}


.MuiOutlinedInput-notchedOutline {
  border-width: 1.5px !important;
  border-color: white !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1664C0 !important;
}

.MuiSelectLabel-root {
  color: white !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.flash-green {
  animation: flash-green .5s;
}

@keyframes flash-green {
  0%, 100% {
      color: white;
  }
  /* 25% {
    color: rgb(190, 203, 0);
  } */
  50% {
      color: rgb(255, 140, 0);
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  input {
    color: white !important;
  }
